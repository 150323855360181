import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import svLocale from 'date-fns/locale/sv';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { getAccountInfo } from './app/api/user';
import { addUserNotifications } from './app/components/hooks/useNotificaitonHook';
import Main from './app/container/Main';
import { useAppDispatch } from './app/reducers/hooks';
import { authService } from './services/AuthService';
import { streamService } from './services/StreamService';

export default function App() {
  console.log('Environment: ', process.env.REACT_APP_PROFILE);
  console.log('auth: ', process.env.REACT_APP_KEYCLOAK_CONFIG);
  console.log('booking: ', process.env.REACT_APP_BOOKING_URL);
  console.log('ws: ', process.env.REACT_APP_WS_URL);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (authService.isLoggedIn()) {
      getAccountInfo(dispatch);
      streamService.start(authService.getToken() as string);
      addUserNotifications(dispatch);
    }
  }, [dispatch]);

  return (
    <LocalizationProvider adapterLocale={svLocale} dateAdapter={AdapterDateFns}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </LocalizationProvider>
  );
}
