import { Box } from '@mui/system';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import Roles from '../../../models/Roles';
import { authService } from '../../../services/AuthService';
import { Navigation, Routes } from '../../components/Navigation';
import { InAppNotificaitons } from '../Account/Notification/InAppNotification/InAppNotifications';
import { CalendarPage } from '../Calendar';
import { NotFoundPage } from '../ErrorBoundary';
import { FeedPage } from '../Feed/FeedPage';
import { HistoryPage } from '../History/HistoryPage';
import { LeaguePage } from '../League';
import { ProductionPage } from '../Production/ProductionPage';
import { Workspace } from '../Workspace';

const Main = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        paddingTop: '80px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Navigation />
      <Box sx={{ flex: '1', overflow: 'hidden' }}>
        <RouterRoutes>
          <Route path="/" element={<CalendarPage />} />
          <Route path={Routes.events} element={<CalendarPage />} />
          <Route
            path={Routes.productions}
            element={
              authService.hasRole([
                Roles.ADMIN,
                Roles.PRODUCTION_EDITOR,
                Roles.PRODUCTION_CREATOR,
                Roles.PRODUCTION_VIEWER,
              ]) ? (
                <ProductionPage />
              ) : (
                <CalendarPage />
              )
            }
          />

          <Route
            path={Routes.league}
            element={
              authService.hasRole([
                Roles.ADMIN,
                Roles.LEAGUE_CREATOR,
                Roles.LEAGUE_EDITOR,
                Roles.LEAGUE_VIEWER,
              ]) ? (
                <LeaguePage />
              ) : (
                <CalendarPage />
              )
            }
          />

          {/* <RolesRoute
            path={Routes.feeds}
            roles={[
              Roles.ADMIN,
              Roles.FEED_CREATOR,
              Roles.FEED_EDITOR,
              Roles.FEED_VIEWER,
            ]}
          >
            <FeedPage />
          </RolesRoute> */}

          <Route
            path={Routes.feeds}
            element={
              authService.hasRole([
                Roles.ADMIN,
                Roles.FEED_CREATOR,
                Roles.FEED_EDITOR,
                Roles.FEED_VIEWER,
              ]) ? (
                <FeedPage />
              ) : (
                <CalendarPage />
              )
            }
          />
          <Route
            path={Routes.notifications}
            element={
              authService.hasRole([
                Roles.ADMIN,
                Roles.PRODUCTION_CREATOR,
                Roles.PRODUCTION_EDITOR,
                Roles.PRODUCTION_VIEWER,
              ]) ? (
                <InAppNotificaitons />
              ) : (
                <CalendarPage />
              )
            }
          />
          <Route
            path={Routes.workspace}
            element={
              authService.hasRole([
                Roles.ADMIN,
                Roles.PRODUCTION_CREATOR,
                Roles.PRODUCTION_EDITOR,
                Roles.PRODUCTION_VIEWER,
              ]) ? (
                <Workspace />
              ) : (
                <CalendarPage />
              )
            }
          />
          <Route
            path={Routes.history}
            element={
              authService.hasRole([
                Roles.ADMIN,
                Roles.EVENT_VIEWER,
                Roles.FEED_VIEWER,
                Roles.PRODUCTION_VIEWER,
                Roles.LEAGUE_VIEWER,
              ]) ? (
                <HistoryPage />
              ) : (
                <CalendarPage />
              )
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </RouterRoutes>
      </Box>
    </Box>
  );
};

export default Main;
