import { Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  buildLocalJSDate,
  minutesToTime,
  nowIsoDate,
} from '../../../../models/dates';
import { DEFAULT_DURATION } from '../../../../models/productions';
import { isNewId } from '../../../../models/records';
import Roles from '../../../../models/Roles';
import { authService } from '../../../../services/AuthService';
import { staticService } from '../../../../services/StaticService';
import { Routes } from '../../../components/Navigation';
import {
  clearDetails,
  getSlice,
  updateDetails,
} from '../../../reducers/leaguesSlice';
import LeagueAudio from './General/LeagueAudio';
import { LeagueOptions } from './General/LeagueOptions';
import LeagueStatus from './General/LeagueStatus';
import { LeagueTextField } from './General/LeagueTextField';
import LeagueRights from './Right/LeagueRights';
import LeagueSeasons from './Season/LeagueSeasons';
import SeasonSource from './Season/SeasonSource';
import {
  createLeagueCard,
  createLeagueCardContent,
  createLeagueContainer,
  createLeagueHeader,
  generalInfo,
  generarlInfoContent,
  leageInfoField,
  sourceAudioStack,
  stack,
} from './style';

const DEFAULT_TIME_DURATION: string = minutesToTime(DEFAULT_DURATION);
const DEFAULT_OFFSET_START: number = -5;
export function LeagueDetail() {
  const { details } = useSelector(getSlice);
  const id = details.data._id;
  const league = details.data;
  const duration = league?.default_duration
    ? league.default_duration
    : DEFAULT_TIME_DURATION;
  const defaultDurationTimeStamp = buildLocalJSDate(nowIsoDate(), duration);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.LEAGUE_EDITOR]);

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(clearDetails());
      navigate(Routes.league);
    };
  }, [navigate, dispatch]);

  return (
    <Box sx={createLeagueContainer}>
      <Stack sx={createLeagueHeader}>
        {league._id && (
          <Stack sx={leageInfoField}>
            <Typography variant="h5">LeagueId: </Typography>
            <Typography variant="subtitle1">
              {isNewId(league._id) ? '' : league._id}
            </Typography>
          </Stack>
        )}
        {league._id && (
          <Stack sx={leageInfoField}>
            <Typography variant="h5">League name: </Typography>
            <Typography variant="subtitle1">{league.name}</Typography>
          </Stack>
        )}
      </Stack>
      <Card sx={createLeagueCard}>
        <Box sx={stack}>
          <CardContent sx={createLeagueCardContent}>
            <Box sx={generalInfo}>
              <Stack sx={generarlInfoContent}>
                <LeagueTextField
                  disabled={readOnly}
                  name="full-name"
                  label="Full Name*"
                  value={league.name}
                  error={details.errors['name']}
                  onChange={(newName: string) => {
                    dispatch(updateDetails({ name: newName }));
                  }}
                />
                <LeagueTextField
                  disabled={readOnly}
                  name="short-name"
                  label="Short Name*"
                  value={league.shortName}
                  error={details.errors['shortName']}
                  onChange={(newShortName: any) => {
                    dispatch(updateDetails({ shortName: newShortName }));
                  }}
                />
                <LeagueTextField
                  disabled={readOnly}
                  name="abbreviation"
                  label="Abbreviation*"
                  value={league.abbreviationName}
                  helperText={details.errors['abbreviationName']}
                  error={details.errors['abbreviationName']}
                  onChange={(newName: string) => {
                    dispatch(updateDetails({ abbreviationName: newName }));
                  }}
                />
                <LeagueOptions
                  disabled={readOnly}
                  label="Sport"
                  id="sport"
                  value={league.sportType}
                  error={details.errors['sportType']}
                  options={staticService.data.sports.all.map((sport: any) => ({
                    value: sport.internalName,
                    label: sport.name,
                  }))}
                  onChange={(newSport: any) => {
                    dispatch(updateDetails({ sportType: newSport }));
                  }}
                />

                <LeagueOptions
                  disabled={readOnly}
                  label="Rule Set*"
                  id="rule-set"
                  value={league.ruleSet}
                  error={details.errors['ruleSet']}
                  options={staticService.data.ruleSets.all
                    .map((ruleSet: any) => ({
                      value: ruleSet,
                      label: ruleSet,
                    }))
                    .concat([{ value: '', label: 'N/A' }])}
                  onChange={(newRuleSet: any) => {
                    dispatch(updateDetails({ ruleSet: newRuleSet }));
                  }}
                />
                <TextField
                  type="number"
                  label="Default Start Offset (min)"
                  variant="standard"
                  value={league.default_start_offset}
                  onChange={(e: any) => {
                    dispatch(
                      updateDetails({
                        default_start_offset: e.target.value,
                      }),
                    );
                  }}
                  margin="normal"
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimeField
                    label="Default duration (HH:mm)"
                    format="HH:mm"
                    value={defaultDurationTimeStamp}
                    variant="standard"
                    onBlur={(e: any) => {
                      dispatch(
                        updateDetails({
                          default_duration: e.target.value,
                        }),
                      );
                    }}
                  />
                </LocalizationProvider>
              </Stack>
              <LeagueStatus
                disabled={readOnly}
                isDisabled={league.disabled}
                onChange={(newValue: any) => {
                  dispatch(updateDetails({ disabled: newValue }));
                }}
              />
            </Box>
            <Stack sx={sourceAudioStack}>
              <SeasonSource
                disabled={readOnly}
                league={league}
                onChange={(newValues: any) => {
                  dispatch(updateDetails(newValues));
                }}
              />
              <LeagueAudio
                disabled={readOnly}
                league={league}
                onChange={(newValue: any) => {
                  dispatch(updateDetails({ audio: newValue }));
                }}
              />
            </Stack>
            <LeagueSeasons
              disabled={readOnly}
              league={league}
              onChange={(newSeasons: any) => {
                dispatch(updateDetails(newSeasons));
              }}
            />
            <LeagueRights
              disabled={readOnly}
              league={league}
              onChange={(newRights: any) => {
                dispatch(updateDetails({ rights: newRights }));
              }}
            />
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}
