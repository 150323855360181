import { keyBy, orderBy } from 'lodash';
import { DisplaySet } from '../app/api/common';
import calendarApi from './APIService';
import auditApi from './AuditService';

class StaticService {
  status: any = {
    public: {},
    private: {},
  };

  data: any = {};

  async init(isLoggedIn: any) {
    await this.initPublic();

    if (isLoggedIn) {
      await this.initPrivate();
    }
  }

  clearPrivate() {
    this.status.private = {};
  }

  async updatePublic() {
    await this.load('leagues', 'leagues');
  }

  async updatePrivate() {
    await this.load('allLeagues', 'leagues?status=all');
  }

  // Internal

  async initPublic() {
    this.initRealm('public', () => [
      this.load('sports', 'sports'),
      this.load('leagues', 'leagues'),
      this.load('countries', '/static/countries', 'code'),
      this.load('ruleSets', '/static/ruleset'),
      this.load('providers', 'providers'),
    ]);
  }

  async initPrivate() {
    this.initRealm('private', () => [
      this.load('channels', 'static/channel'),
      this.load('audioLayouts', 'static/audio_layout'),
      this.load('skills', 'static/skills'),
      this.load('commentators', 'static/commentators'),
      this.load('locations', 'static/location'),
      this.load('prodLeaders', 'static/productionleaders'),
      this.load('prodTemplates', 'static/prod_templates'),
      this.load('runTypes', 'static/runtype'),
      this.load('streams', 'static/streams'),
      this.load('deliveryTypes', 'static/delivery_type'),
      this.load('feedTemplates', 'static/feed_templates'),
      this.load('allLeagues', 'leagues?status=all'),
      this.loadFromAudit('activites', '/audit/activity'),
    ]);
  }

  async initRealm(
    realm: string,
    getPromises: { (): Promise<void>[]; (): Promise<void>[]; (): any },
  ) {
    this.status[realm].ready = false;

    try {
      this.status[realm].promise = Promise.all(getPromises());
      await this.status[realm].promise;

      this.status[realm].ready = true;
    } catch (e) {
      console.error(e);
    }
  }

  async load(key: string, path: string, idField = 'id') {
    let items;
    try {
      items = await calendarApi.get(path);
    } catch (error) {
      console.log(`not loaded ${error}`);
    }

    if (!idField) {
      this.data[key] = items;
      return;
    }

    if (!items || items.length === 0) {
      this.data[key] = {
        all: [],
        byId: {},
      };
      return;
    }

    this.data[key] = {
      all: [...items].sort((one, other) =>
        (one.name || one.label || '').localeCompare(
          other.name || other.label || '',
        ),
      ),
      byId: Object.fromEntries(
        items?.map((item: { [x: string]: any }) => [item[idField], item]),
      ),
    };
  }

  async loadFromAudit(key: string, path: string) {
    const staticActivities: any = await auditApi.get(path);
    let activityOptions = [];
    if (staticActivities && staticActivities.length > 0) {
      activityOptions = staticActivities;
    }
    this.data[key] = activityOptions;
  }

  getSportName(leagueSport: any) {
    const value = this.data.sports.all.find(
      (sport: { internalName: any }) => sport.internalName === leagueSport,
    );
    if (value) {
      return value.name;
    }
    return leagueSport;
  }

  getOptions(type: string) {
    switch (type) {
      case 'sport':
        return this.data.sports.all;
      case 'league_id':
        return this.data.leagues.all;
      default:
        return [];
    }
  }

  getByIdOptions(type: string) {
    switch (type) {
      case 'sport':
        return this.data.sports.byId;
      case 'league_id':
        return this.data.leagues.byId;
      default:
        return [];
    }
  }

  getSports(): DisplaySet {
    const items = this.data.sports.all
      .map((item: any) => ({
        value: item.internalName,
        label: item.name,
      }))
      .concat({ value: '', label: 'N/A' });
    return keyBy(items, 'value');
  }

  getLeagues(): DisplaySet {
    const items = this.data.leagues.all
      .map((league: any) => ({ value: league.id, label: league.name }))
      .concat({ value: '', label: 'N/A' });
    return keyBy(items, 'label');
  }

  getLeaguesBySports(sport: any): DisplaySet {
    const items = this.data.leagues.all
      .filter(
        (league: any) => league.sportType.toLowerCase() === sport.toLowerCase(),
      )
      .map((league: any) => ({ value: league.id, label: league.name }))
      .concat({ value: '', label: 'N/A' });
    return keyBy(items, 'label');
  }

  getDeliveryType(country: any): DisplaySet {
    const items = this.data.deliveryTypes.all
      .filter((item: any) => item.country === country)
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'value');
  }

  getRunTypes(): DisplaySet {
    const items = this.data.runTypes.all
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'value');
  }

  getRunTypesByCountry(country: any): DisplaySet {
    const items = this.data.runTypes.all
      .filter((item: any) => item.country === country)
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'value');
  }

  getFeedTemplates(country: any): DisplaySet {
    const feedTemplates = this.data.feedTemplates.all;
    const sortedTemplates = feedTemplates.sort((a: any, b: any) =>
      a.name.localeCompare(b.name),
    );
    const items = sortedTemplates
      .filter((item: any) => item.country === country)
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'label');
  }

  getFeedTemplateData(id: any) {
    if (!id) {
      return {};
    }
    let template = this.data.feedTemplates.all.find(
      (template: any) => template.id === id,
    ).data;
    if (template?.league_id) {
      const leagueSportType = this.data.leagues.all.find(
        (league: any) => league.id === template.league_id,
      ).sportType;
      const sportType = this.data.sports.all.find(
        (sport: any) => sport.internalName === leagueSportType,
      ).internalName;
      template = {
        ...template,
        sport_type: sportType,
      };
    }
    return template;
  }

  getProductionTemplates(): DisplaySet {
    const productionTemplates = this.data.prodTemplates.all;
    const sortedItems = orderBy(productionTemplates, 'global', 'asc');
    const items = sortedItems
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'label');
  }

  getProductionTeemplatesByCountry(country: string): DisplaySet {
    const productionTemplates = this.data.prodTemplates.all;
    const sortedItems = orderBy(productionTemplates, 'global', 'asc');
    const items = sortedItems
      .filter((item: any) => item.data?.country === country)
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'label');
  }

  getCountries(): DisplaySet {
    const items = this.data.countries.all
      .map((item: any) => ({
        value: item.code,
        label: item.code.toUpperCase(),
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'value');
  }

  getChannels(): DisplaySet {
    const items = this.data.channels.all
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'value');
  }

  getChannelsByCountry(country: any): DisplaySet {
    const items = this.data.channels.all
      .filter((item: any) => item.country === country)
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'label');
  }

  getStreams(): DisplaySet {
    const items = this.data.streams.all
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'label');
  }

  getProductionLeader(): DisplaySet {
    const items = this.data.prodLeaders.all
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'label');
  }

  getProductionLeaderByCountry(country: string): DisplaySet {
    const items = this.data.prodLeaders.all
      .filter((item: any) => item.country === country)
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .concat([{ value: '', label: 'N/A' }]);
    return keyBy(items, 'label');
  }

  getActivities(): DisplaySet {
    const items = this.data.activites
      .map((activity: string) => ({
        value: activity,
        label: activity,
      }))
      .concat([{ value: 0, label: 'All' }]);
    return items;
  }

  validateCountryChange(country: string, item: any): any {
    const runtype = this.data.runTypes.all.find(
      (runType: any) => runType.id === item.run_type_id,
    );
    const template = this.data.prodTemplates.all.find(
      (template: any) => template.id === item.template_two,
    );

    const channel = this.data.channels.all.find(
      (channel: any) => channel.id === item.channel_id,
    );

    const productionLeader = this.data.prodLeaders.all.find(
      (leader: any) => leader.id === item.production_leader_id,
    );

    const updatedValues: any = {
      country,
      ...(runtype && runtype.country !== country && { run_type_id: 0 }),
      ...(template && template.data.country !== country && { template_two: 0 }),
      ...(channel && channel.country !== country && { channel_id: 0 }),
      ...(productionLeader &&
        productionLeader.country !== country && { production_leader_id: 0 }),
    };

    return updatedValues;
  }
}

export const staticService = new StaticService();
