import { useEffect, useRef } from 'react';
import Roles from '../../../../models/Roles';
import { authService } from '../../../../services/AuthService';
import { ColumnSetting, ColumnType } from '../../../components/InfiniteTable';
import { InfiniteTable } from '../../../components/InfiniteTable/InfiniteTable';
import { BaseItem, DataType } from '../../../reducers/dataSlice';
import { useAppDispatch, useAppSelector } from '../../../reducers/hooks';
import {
  actions,
  getSlice,
  refreshItem,
  resetItemAction,
} from '../../../reducers/workspaceSlice';
import { useEventColumnSettings } from '../../Calendar/EventsTable/EventColumnSettings';
import { eventRowStyle, columns } from '../../Calendar/EventsTable/style';
import { EventCounter } from './EventCounter';
import { getRowSize } from './hooks';
import { useSubRow } from './useSubRow';
import { useLiveUpdatesOnFilters } from '../../../components/hooks/websocketHook';
import { retrofitEvent } from '../../../../models/events';
import { retrofitProductions } from '../../../../models/productions';
import { retrofitFeed, retrofitFeeds } from '../../../../models/feeds';
import api from '../../../../services/APIService';
import { useToggleRow } from './useToggleRow';
import { filterToQueryParams } from '../../../../services/common';
import { isNewId } from '../../../../models/records';
import { expandRowStyle } from './style';

export function EventsTable() {
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.EVENT_CREATOR]);
  const dataState = useAppSelector(getSlice);
  const ref: any = useRef();
  const dispatch = useAppDispatch();
  useLiveUpdatesOnFilters(DataType.Workspace, {
    onOnline: (online: boolean) => {
      console.log(`ready for getting updates: ${online}`);
    },
    onDataUpdate: (data: any[]) => {
      //dispatch data update
      data.forEach(item => {
        const event = retrofitEvent(item);
        dispatch(
          refreshItem({
            ...event,
            productions: retrofitProductions(item?.productions),
            feeds: retrofitFeeds(item?.feeds),
          }),
        );
        setTimeout(() => {
          dispatch(resetItemAction(event));
        }, 3000);
      });
    },
  });

  const columnsSettings = [
    {
      key: 'aggregated_detail',
      header: '',
      datatype: ColumnType.Custom,
      style: columns.subCount,
      editable: false,
      render: (column: ColumnSetting, item: BaseItem) => (
        <EventCounter item={item} />
      ),
    },
    ...useEventColumnSettings(DataType.Workspace),
  ];

  const renderSubRow = useSubRow;

  useEffect(() => {
    ref.current?.resetAfterIndex(0);
  }, [dataState.subItems, dataState.creates]);

  return (
    <InfiniteTable
      api={api}
      minWidth="2500px"
      columns={columnsSettings}
      sub
      subTable={(item: any) => renderSubRow(item)}
      disableSubRow={(item: BaseItem) =>
        !!item.deleted_time || isNewId(item._id)
      }
      expandRowStyle={expandRowStyle}
      toggleRow={useToggleRow}
      slice={dataState}
      actions={actions}
      readOnly={readOnly}
      type={DataType.Workspace}
      rowStyle={eventRowStyle}
      passRef={ref}
      getRowHeight={(index: number, rows: any, creates: any, subItems: any) => {
        return getRowSize({ index, rows, creates, subItems });
      }}
      filterToQuery={filterToQueryParams}
    />
  );
}
