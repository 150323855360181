import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { consolidateFilters } from '../../../models/filters';
import {
  getFilterStorage,
  populateFilterStorage,
} from '../../../models/storage';
import { FilterAction, save } from '../../api/user';
import { DataType } from '../../reducers/dataSlice';
import { setFilter } from '../../reducers/filterSlice';
import { useAppSelector } from '../../reducers/hooks';
import { RouteTypes } from '../Navigation/Routes';
import { FilterCriterias, FilterState } from './index';

export function useFilterCallbacks(filterState: FilterState) {
  const dispatch = useDispatch();

  const onChangeFilter = useCallback(
    (newFilter: {
      customFilter: FilterCriterias;
      currentCustomFilters?: FilterCriterias;
      filterPresets: number[];
    }) => {
      const filters = consolidateFilters([
        newFilter.customFilter,
        newFilter.currentCustomFilters,
      ]);
      const allFilters = {
        date: new Date(),
        customFilter: filters,
        filterPresets: [...newFilter.filterPresets],
      };
      dispatch(setFilter(allFilters));
      populateFilterStorage(allFilters);
    },
    [dispatch],
  );
  const onSaveFilter = useCallback(async () => {
    const newFilters = await save({ action: FilterAction.GET, dispatch });
    const presetFilters = Object.values(newFilters)
      .flat()
      .filter((preset: any) => filterState.filterPresets.includes(preset.id))
      .map((preset: any) => preset.filter);
    const filter = consolidateFilters([
      ...presetFilters,
      filterState.customFilter,
    ]);

    dispatch(setFilter({ filter }));
  }, [filterState, dispatch]);

  return { onChangeFilter, onSaveFilter };
}
/**
 * Apply the filters for the given slice.
 * Initiliaze the filter from the local storage.
 * @param type '
 * @param applyFilter
 */
export const useFilters = (type: DataType, applyFilter: any) => {
  const dispatch = useDispatch();
  const { event, production, feed, workspace, fromDate, toDate } =
    useAppSelector(state => state.filter);
  const navigate = useNavigate();
  let listeningFilter: any;
  switch (type) {
    case DataType.Event:
      listeningFilter = event;
      break;
    case DataType.Production:
      listeningFilter = production;
      break;
    case DataType.Workspace:
      listeningFilter = workspace;
      break;
    case DataType.Feed:
      listeningFilter = feed;
      break;
    default:
      console.log(`not supported ${type}`);
  }

  const { queryId, sessionId } = getFilterStorage();

  //Using memo to run once when loading the page to get the default filter defined on localstore if any.
  const defaultFilter = useMemo(() => {
    const { customFilter, filterPresets } = getFilterStorage();
    if (queryId) {
      const storedIds = customFilter?.ids || [];
      if (!storedIds.find((stored: any) => stored.id === queryId)) {
        const filter = {
          customFilter: {
            ...customFilter,
            ids: [{ id: queryId, type }, ...storedIds],
          },
          filterPresets: filterPresets ? [...filterPresets] : [],
        };
        populateFilterStorage(filter);
      }
      navigate(RouteTypes[type]);
    }

    let custom = {};
    let presets: any[] = [];

    custom = customFilter && customFilter;
    presets = filterPresets && filterPresets;

    if (sessionId) {
      navigate(RouteTypes[type]);
    }

    return custom || presets
      ? { customFilter: custom, filterPresets: presets }
      : undefined;
  }, [type, navigate, queryId, sessionId]);

  //Setting the filter on state
  useEffect(() => {
    if (defaultFilter) {
      dispatch(setFilter(defaultFilter));
    }
  }, [dispatch, type, defaultFilter]);

  //Applying the filter
  useEffect(() => {
    const a = JSON.stringify(listeningFilter);
    console.log(`dispatching applyfilter  ${a} ${fromDate} ${fromDate}`);
    dispatch(applyFilter());
  }, [dispatch, listeningFilter, fromDate, toDate, applyFilter]);
};
