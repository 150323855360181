import { createTableStyle } from '../../../components/InfiniteTable/createTableStyle';

const tableStyle: any = createTableStyle({
  id: {
    width: 60,
    'a:-webkit-any-link': {
      color: '#cc0099',
      textAlign: 'center',
      textDecoration: 'none',
    },
    'a:-webkit-any-link:hover': {
      color: '#ff0000',
    },
  },
  week: 40,
  day: 40,
  date: 80,
  start: 48,
  end: {
    width: 60,
    button: {
      textAlign: 'left',
      padding: 'revert',
    },
  },
  duration: 48,
  country: {
    width: 70,
    button: {
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  },
  channel: 90,
  sport: 80,
  league: {
    minWidth: 180,
    overflow: 'hidden',
    button: {
      textAlign: 'left',
    },
  },
  title: {
    flex: '2 1 100px',
    overflow: 'hidden',
    minWidth: 120,
    textAlign: 'center',
    maxWidth: 480,
    button: {
      textAlign: 'left',
    },
  },
  runtype: 80,
  template_two: 80,
  stream: 100,
  audio: {
    width: 140,

    '.-tags': {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '3px',

      '> *': {
        padding: '3px',
        borderRadius: '6px',
        backgroundColor: '#048',
        color: '#fff',
        fontWeight: 400,
      },

      '> *[data-value="DOLBY E"]': {
        backgroundColor: '#f40',
      },
      '> *[data-value="N/A"]': {
        backgroundColor: '#E1E3E5',
      },
    },
  },
  takers: {
    width: '350px',

    '._option ': {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      backgroundColor: '#eee',
      border: '2px solid transparent',
      fontSize: '0.75rem',
      fontWeight: 400,
      textTransform: 'uppercase',

      '&.-mcr-active': {
        backgroundColor: '#7d83b6',
        color: '#fff',
      },
      '&.-rec-active': {
        backgroundColor: '#a6087f',
        color: '#fff',
      },
      '&.-focus': {
        borderColor: '#f40',
      },
    },
  },

  scte: {
    width: '350px',

    '._option ': {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      backgroundColor: '#eee',
      border: '2px solid transparent',
      fontSize: '0.75rem',
      fontWeight: 400,
      textTransform: 'uppercase',

      '&.-rec-active': {
        backgroundColor: '#7d83b6',
        color: '#fff',
      },
      '&.-focus': {
        borderColor: '#f40',
      },
    },
  },
  leader: {
    width: 90,

    button: {
      textAlign: 'left',
    },
  },
  commentator: {
    width: 90,
  },

  admin: {
    width: 150,
  },
});

export const productionRowStyle = {
  ...tableStyle.rowStyle,
  '._badge': {
    backgroundColor: '#b17e9b',
  },
};

export const { columns, rowStyle } = tableStyle;

export const talentTableCell = {
  color: 'white',
  width: 'none',
  padding: 1,
  maxWidth: 60,
  wordWrap: 'break-word',
};

export const talentCountryCell = {
  color: 'white',
  width: 'none',
  padding: 1,
  maxWidth: 9,
};
