/**
 * Filter criterias that can be included in a filter
 */
export type FilterCriterias = {
  run_type_id?: number[];
  channel_id?: number[];
  stream_equipment?: number[];
  production_leader_id?: number[];
  delivery_type_id?: number[];
  editorial?: number[];
  sport_id?: number[];
  league_id?: number[];
  countries?: string[];
  level?: number;
  notification_days?: string[];
};
/**
 * Filter type that defines the filter criterias available depending on what page the user is in.
 */
export const TypeFilters: any = {
  types: ['global', 'event', 'feed', 'production', 'workspace'] as const,
  global: ['sport_id', 'league_id', 'countries'],
  event: ['level'],
  workspace: [
    'run_type_id',
    'channel_id',
    'stream_equipment',
    'production_leader_id',
    'delivery_type_id',
    'editorial',
    'level',
    'mcr',
    'takers',
    'scte',
  ],
  feed: ['delivery_type_id', 'mcr'],
  production: [
    'run_type_id',
    'channel_id',
    'stream_equipment',
    'production_leader_id',
    'editorial',
    'takers',
    'scte',
  ],
};

export const getTypeByFilters = (filters: any) => {
  const hasFeedFilters = filters.some((filter: any) =>
    TypeFilters['feed'].includes(filter),
  );
  const hasGlobalFilters = filters.some((filter: any) =>
    TypeFilters['global'].includes(filter),
  );

  const hasProductionFilters = filters.some((filter: any) =>
    TypeFilters['production'].includes(filter),
  );

  const hasEventFilters = filters.some((filter: any) =>
    TypeFilters['event'].includes(filter),
  );

  if (
    (hasFeedFilters &&
      !hasProductionFilters &&
      !hasEventFilters &&
      !hasGlobalFilters) ||
    (hasGlobalFilters &&
      hasFeedFilters &&
      !hasProductionFilters &&
      !hasEventFilters)
  ) {
    return 'feed';
  }

  if (
    (hasProductionFilters &&
      !hasFeedFilters &&
      !hasEventFilters &&
      !hasGlobalFilters) ||
    (hasGlobalFilters &&
      hasProductionFilters &&
      !hasFeedFilters &&
      !hasEventFilters)
  ) {
    return 'production';
  }

  if (
    (hasEventFilters &&
      !hasFeedFilters &&
      !hasProductionFilters &&
      !hasGlobalFilters) ||
    (hasGlobalFilters &&
      hasEventFilters &&
      !hasProductionFilters &&
      !hasFeedFilters)
  ) {
    return 'event';
  }

  if (
    hasGlobalFilters &&
    !hasFeedFilters &&
    !hasProductionFilters &&
    !hasEventFilters
  ) {
    return 'global';
  }

  if (
    hasEventFilters ||
    hasFeedFilters ||
    hasProductionFilters ||
    hasGlobalFilters
  ) {
    return 'workspace';
  }

  return 'global';
};

export const getAllowedPresetFilters = (tab: string, presetType: string) => {
  return [...TypeFilters[tab], ...TypeFilters['global']];
};

export type PresetType = (typeof TypeFilters.types)[number];

export type GlobalFilters = Pick<
  FilterCriterias,
  'sport_id' | 'league_id' | 'countries'
>;

export type WorkspaceFilters = Pick<
  FilterCriterias,
  | (GlobalFilters & 'run_type_id')
  | 'channel_id'
  | 'stream_equipment'
  | 'production_leader_id'
  | 'delivery_type_id'
  | 'editorial'
  | 'level'
>;

export type FeedFilters = Pick<
  FilterCriterias,
  GlobalFilters & 'delivery_type_id'
>;

export type EventFilters = Pick<FilterCriterias, GlobalFilters & 'level'>;

export type ProductionFilters = Pick<
  FilterCriterias,
  | (GlobalFilters & 'run_type_id')
  | 'channel_id'
  | 'stream_equipment'
  | 'production_leader_id'
  | 'editorial'
>;

/**
 * Filter defined by the users and saves on the profile.
 * Every filter has an id which will be part of the FilterState.
 */
export type FilterPreset<T> = {
  defaultFilter: boolean;
  id: number;
  name: string;
  filter: T;
};

export type FilterPresets = {
  [key: string]: any;
  global: FilterPreset<GlobalFilters>[];
  production: FilterPreset<ProductionFilters>[];
  feed: FilterPreset<FeedFilters>[];
  workspace: FilterPreset<WorkspaceFilters>[];
  event: FilterPreset<EventFilters>[];
};

/**
 * Filter state for the current user
 * Combination of ad-hoc filters and filters already saved on profile.
 */
export type FilterState = {
  customFilter: FilterCriterias;
  filterPresets: number[];
};
