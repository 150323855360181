import { RemoveCircle } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { Key, useMemo } from 'react';
import { FilterOptions } from './FilterOptions';

interface FilterCriteraProps {
  criteria: { field: string; value: any }[];
  allowFields: string[];
  presetType: string;
  onChange(value: any): any;
}

export function FilterCriteria({
  criteria,
  allowFields,
  presetType,
  onChange,
}: FilterCriteraProps) {
  const onDeleteRow = (index: number) =>
    onChange(criteria.filter((r, i) => i !== index));

  const onChangeRow = (index: number, field: any, value: any) => {
    const newRows = [...criteria];
    newRows[index] = {
      field,
      value:
        field === criteria[index].field
          ? value
          : getDefaultValueForField(field, criteria, index),
    };
    onChange(newRows);
  };

  const onAdd = () =>
    onChange([
      ...criteria,
      {
        field: allowFields[0],
        value: getDefaultValueForField(allowFields[0], criteria, -1),
      },
    ]);

  const onClear = () => onChange([]);

  return (
    <Stack gap={1}>
      {criteria.map(({ field, value }, i) => (
        <FilterRow
          key={`${field}=${value}=${nanoid()}`}
          index={i}
          rows={criteria}
          field={field}
          value={value}
          allowFields={allowFields}
          presetType={presetType}
          onChange={onChangeRow}
          onDelete={onDeleteRow}
        />
      ))}

      <Stack direction="row" justifyContent="left" gap={0}>
        <Button onClick={onAdd} sx={{ textTransform: 'none' }}>
          Add Filter
        </Button>
        <Button
          onClick={onClear}
          disabled={criteria?.length === 0}
          sx={{ textTransform: 'none' }}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  );
}

interface FilterRowProps {
  index: number;
  rows: any[];
  field: string;
  value: any;
  allowFields: string[];
  presetType: string;
  onChange(index: number, field: any, value: any): any;
  onDelete(index: number): any;
}

function FilterRow({
  index,
  rows,
  field,
  value,
  allowFields,
  onChange,
  onDelete,
}: FilterRowProps) {
  let currentIcon: string;
  const allOptions = useMemo(() => FilterOptions[field].getOptions(), [field]);

  const filteredOptions = useMemo(() => {
    const blockedValues = getBlockedValues(field, rows, index);

    return allOptions.filter(
      (option: { value: any; label: undefined }) =>
        !blockedValues.includes(option.value) && option.label !== undefined,
    );
  }, [rows, index, field, allOptions]);

  return (
    <Stack direction="row" gap={1}>
      <Select
        size="small"
        value={field}
        onChange={e => onChange(index, e.target.value, value)}
        sx={{ width: '120px' }}
      >
        {allowFields.map((fieldName: Key) => (
          <MenuItem key={fieldName as string} value={fieldName as string}>
            {FilterOptions[fieldName as string].label}
          </MenuItem>
        ))}
      </Select>

      <Autocomplete
        size="small"
        disableClearable
        value={filteredOptions.find((option: { value: any; icon: any }) =>
          option?.icon ? '' : option.value === value,
        )}
        options={filteredOptions}
        getOptionLabel={(option: any) =>
          typeof option.label !== 'string'
            ? option.value.toString()
            : option.label
        }
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              id={option?.icon ? option.value : option.label}
              key={option.value}
            >
              {option.label}
            </li>
          );
        }}
        isOptionEqualToValue={option => option.value === value}
        onChange={(e, option: any) => {
          onChange(index, field, option.value);
        }}
        renderInput={params => {
          const option = filteredOptions.find(
            (option: { value: any }) => option.value === value,
          );
          if (option?.icon) {
            return (
              <TextField
                {...params}
                id="icon-text-field"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {option?.icon && (
                        <InputAdornment position="start">
                          <Icon
                            component={option.icon}
                            sx={{ color: option.color }}
                          />
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                size="small"
              />
            );
          } else {
            return <TextField {...params} />;
          }
        }}
        sx={{ flex: 1 }}
      />
      <IconButton aria-label="remove" onClick={() => onDelete(index)}>
        <RemoveCircle />
      </IconButton>
    </Stack>
  );
}

// Helpers

function getBlockedValues(field: any, rows: any[], index: any) {
  return rows
    .filter((row: { field: any }, i: any) => i !== index && row.field === field)
    .map((row: { value: any }) => row.value);
}

export function getDefaultValueForField(
  field: string,
  rows: any[],
  index: number,
) {
  const blockedValues = getBlockedValues(field, rows, index);
  const allOptions = FilterOptions[field].getOptions();
  const filtered = allOptions.filter(
    (option: any) => option.label !== undefined,
  );
  return filtered.find((option: any) => !blockedValues.includes(option.value))
    ?.value;
}
